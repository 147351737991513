/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useTranslation } from "react-i18next";

import { calculateSignupDate } from "./utils";
import { FacilityManagementFilterConditionData } from "../../../models/FacilityFilterConditionData";
import { LocationData } from "../../../models/LocationData";
import { OrganizationData } from "../../../models/OrganizationData";
import { getFirstCategory } from "../../../utils/getMainCategory";
import { Pagination } from "../../Pagination";
import ListTopBar from "../ListTopBar";

export interface IFacilityManagementListProps {
  dataList: LocationData[];
  totalCount: number;
  conditionFormData: FacilityManagementFilterConditionData;
  filterFormData: any;
  organizationId: string;
  onClickFilter: () => void;
  onClickClearFilterCondition: () => void;
  onChangeFilterFormData: (filterFormData: any) => void;
  onClickAssignOrg: (facilityItem: LocationData) => void;
}

export const FacilityManagementList: React.FunctionComponent<
  IFacilityManagementListProps
> = (props) => {
  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(`facilityManagementPage.facilityManagementList.${key}`);

  const {
    dataList,
    totalCount,
    conditionFormData,
    filterFormData, // organizationId,
    onClickFilter,
    onClickClearFilterCondition,
    onChangeFilterFormData,
    onClickAssignOrg,
  } = props;

  const renderLocationAssignedStatus = (item: any) => {
    if (item.organizations.length > 0) {
      return (
        <div className="txt">
          <ul>
            {item.organizations.map(
              (
                organizationItem: OrganizationData,
                organizationIndex: number
              ) => (
                <li key={organizationIndex}>{organizationItem.name}</li>
              )
            )}
          </ul>
        </div>
      );
    }

    if (item.independent_use) {
      return <div className="txt">{t("independent_use")}</div>;
    }

    return <div className="txt red-txt">{t("unassigned")}</div>;
  };

  return (
    <>
      <div className="white-border-panel">
        <ListTopBar
          conditionFormData={conditionFormData}
          filterFormData={filterFormData}
          onClickFilter={() => {
            onClickFilter();
          }}
          onClickClearFilterCondition={() => {
            onClickClearFilterCondition();
          }}
          onChangeFilterFormData={(filterFormData: any) => {
            onChangeFilterFormData(filterFormData);
          }}
        />

        <div className="table-data">
          <div className="row-th">
            <div className="col-th width14">
              <div className="spacing">{t("facility_name")}</div>
            </div>
            <div className="col-th width14 center">
              <div className="spacing">{t("organization_name")}</div>
            </div>
            <div className="col-th width14 center">
              <div className="spacing">{t("loc_admin_present")}</div>
            </div>
            <div className="col-th width10 center">
              <div className="spacing">{t("date_signup_completed")}</div>
            </div>
            <div className="col-th width10 center">
              <div className="spacing">{t("location")}</div>
            </div>
            <div className="col-th width11 center">
              <div className="spacing">{t("category")}</div>
            </div>
            <div className="col-th width14 center">
              <div className="spacing">{t("is_archived")}</div>
            </div>
            <div className="col-th ">
              <div className="spacing"></div>
            </div>
          </div>
          {dataList
            .slice(0, filterFormData.numberPerPage)
            .map((item: LocationData, indexFacility: number) => (
              <div className="row-td" key={indexFacility}>
                <div className="col-td width14">
                  <div className="spacing">
                    <span className="blue-txt">{item.name}</span>
                    {/*
                    <NavLink to={`/location${organizationId ? `/${organizationId}` : ''}/${item.id}`} className='blue-link'>
                      {item.name}
                    </NavLink>
                    */}
                  </div>
                </div>
                <div className="col-td width16 center">
                  <div className="spacing">
                    {renderLocationAssignedStatus(item)}
                  </div>
                </div>
                <div className="col-td width16 center">
                  <div className="spacing">
                    {item?.has_loc_admins ? "True" : "False"}
                  </div>
                </div>
                <div className="col-td width14 center">
                  <div className="spacing">
                    {calculateSignupDate(item).format("YYYY-MM-DD")}
                  </div>
                </div>
                <div className="col-td width11 center">
                  <div className="spacing">
                    <div className="txt">
                      {item.prefecture} {item.city}
                    </div>
                  </div>
                </div>
                <div className="col-td width14 center">
                  <div className="spacing">{getFirstCategory(item)}</div>
                </div>
                <div className="col-td width14 center">
                  <div className="spacing">
                    {item.is_archived ? "True" : "False"}
                  </div>
                </div>
                <div className="col-td center buttons-section">
                  <div className="spacing">
                    <a
                      className="btn btn-blue"
                      onClick={() => {
                        onClickAssignOrg(item);
                      }}
                    >
                      {t("assign_org")}
                    </a>
                    <a
                      className="btn btn-blue"
                      href={`/location/${item.id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("see_screen")}
                    </a>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>

      <Pagination
        filterFormData={filterFormData}
        totalRowsNumber={totalCount}
        onChangeFilterFormData={(filterFormData: any) => {
          onChangeFilterFormData(filterFormData);
        }}
      />
    </>
  );
};

export default FacilityManagementList;

import nprogress from "accessible-nprogress";
import axios from "axios";
import i18next from "i18next";
import { get } from "lodash";
import React from "react";
import { toast } from "react-toastify";

import DemoModeSvc from "./demoModeSvc";
import { loadingChange } from "../actions/dataAction";
import { ToolsMetrics } from "../components/InternalTools/IndividualTools/MetricsNew/types";
import { API_BASE } from "../config";
import { AccountData } from "../models/AccountData";
import { CategoryData } from "../models/CategoryData";
import { ChartData } from "../models/ChartCollectionData";
import { CustomDashboardParams } from "../models/CustomDashboardParams";
import { LabelData } from "../models/LabelData";
import { LocationData } from "../models/LocationData";
import { OrgDailyMetricsFromAPIData } from "../models/MetricsLineChartData";
import { MunicipalityData } from "../models/MunicipalityData";
import { OrganizationData } from "../models/OrganizationData";
import {
  OrgRecommendationData,
  RawOrgRecommendationData,
  sanitizeOrgRecommendationData,
} from "../models/OrgRecommendationData";
import { RecMessageDataFromAPI } from "../models/RecMessageData";
import store from "../store";

export const ERR_TOKEN_IS_NOT_READY = "ERR_TOKEN_IS_NOT_READY";

const axiosInstance = axios.create({
  baseURL: API_BASE,
});

const axiosInstanceNoLoading = axios.create({
  baseURL: API_BASE,
});

const demoMode = DemoModeSvc.current;

axiosInstance.interceptors.request.use(async (config) => {
  if (DataSvc.progressCount === 0) {
    nprogress.configure({ parent: "main" });
    nprogress.start();
    store.dispatch(loadingChange(true));
  }
  DataSvc.progressCount++;

  const token = await DataSvc.getToken();

  if (!token) {
    throw Error(ERR_TOKEN_IS_NOT_READY);
  }

  if (!config.headers) {
    config.headers = {};
  }

  config.headers["Authorization"] = `Bearer ${token}`;
  if (DataSvc.userId) {
    config.headers["AC-User-Id"] = DataSvc.userId;
  }
  if (DataSvc.orgId) {
    config.headers["AC-Org-Id"] = DataSvc.orgId;
  }

  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    countDownProgress();
    return response;
  },
  (error) => {
    countDownProgress();

    const errorItem = error.response?.data?.error;

    // API passes back error as JSON in OAuth error cases
    let errorJson;
    if (errorItem?.message) {
      if (typeof errorItem.message === "object") {
        errorJson = errorItem.message;
      } else {
        try {
          errorJson = JSON.parse(errorItem.message);
        } catch (e) {
          // Not JSON
        }
      }
    }

    if (
      (error.response?.status === 401 &&
        errorItem.message === "Unauthorized in Google") ||
      (error.response?.status === 400 &&
        errorJson?.error_description === "Account has been deleted")
    ) {
      // no toast
      return Promise.reject("Unauthorized in Google");
    } else if (
      !error.response?.config?.noHandleError &&
      error.message !== ERR_TOKEN_IS_NOT_READY
    ) {
      const title = get(error, "response.data.error.title", null);

      let errorMessage;

      if (errorJson?.error_description) {
        errorMessage = errorJson?.error_description;
      } else if (errorJson?.translationKey) {
        errorMessage = i18next.t(`server_error.${errorJson.translationKey}`);
      } else if (errorItem?.message) {
        errorMessage = errorItem.message;
      } else {
        errorMessage = i18next.t("errors.general_error");
      }

      toast.error(
        title ? (
          <>
            <strong>{title}</strong>
            <br />
            {errorMessage}
          </>
        ) : (
          <>{errorMessage}</>
        )
      );
    }
    return Promise.reject(error);
  }
);

axiosInstanceNoLoading.interceptors.request.use((config) => {
  if (!DataSvc.getToken()) {
    throw Error(ERR_TOKEN_IS_NOT_READY);
  }
  return DataSvc.getToken().then((token) => {
    if (!config.headers) {
      config.headers = {};
    }
    config.headers["Authorization"] = `Bearer ${token}`;
    return config;
  });
});

axiosInstanceNoLoading.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const errorItem = error.response?.data?.error;

    // API passes back error as JSON in OAuth error cases
    let errorJson;
    if (errorItem?.message) {
      try {
        errorJson = JSON.parse(errorItem.message);
      } catch (e) {
        // Not JSON
      }
    }

    if (
      (error.response?.status === 401 &&
        errorItem.message === "Unauthorized in Google") ||
      (error.response?.status === 400 &&
        errorJson.error_description === "Account has been deleted")
    ) {
      // no toast
      return Promise.reject("Unauthorized in Google");
    } else if (
      !error.response?.config?.noHandleError &&
      error.message !== ERR_TOKEN_IS_NOT_READY
    ) {
      const title = get(error, "response.data.error.title", null);

      let errorMessage;

      if (errorJson?.error_description) {
        errorMessage = errorJson?.error_description;
      } else if (errorItem?.message) {
        errorMessage = errorItem.message;
      } else {
        errorMessage = i18next.t("errors.general_error");
      }

      toast.error(
        title ? (
          <>
            <strong>{title}</strong>
            <br />
            {errorMessage}
          </>
        ) : (
          <>{errorMessage}</>
        )
      );
    }
    return Promise.reject(error);
  }
);

const countDownProgress = () => {
  DataSvc.progressCount--;
  if (DataSvc.progressCount === 0) {
    nprogress.done();
    store.dispatch(loadingChange(false));
  }
};

declare module "axios" {
  export interface AxiosRequestConfig {
    noHandleError?: boolean;
  }
}

// data service
export default class DataSvc {
  static progressCount = 0;
  static token: Promise<string>;
  static userId?: string;
  static orgId?: string;

  static setToken(token: Promise<string>) {
    DataSvc.token = token;
  }

  static getToken() {
    return DataSvc.token;
  }
  static async getAccountsMe(noLoading?: boolean) {
    const response = await (!noLoading
      ? axiosInstanceNoLoading
      : axiosInstance
    ).get<AccountData>(`/accounts/me`);
    const userId = response?.data?.id;
    const orgId = response?.data?.organization_id;
    if (userId) {
      DataSvc.userId = userId.toString();
    }
    if (orgId) {
      DataSvc.orgId = orgId;
    }
    return demoMode.isDemoModeOn
      ? demoMode.processAccount(response.data)
      : response.data;
  }

  static async getAccountByEmail(email: string) {
    const response = await axiosInstance.get<AccountData[]>(
      `/accounts?email=${encodeURIComponent(email)}`,
      {
        noHandleError: true,
      }
    );
    if (response.data && response.data.length) {
      return demoMode.isDemoModeOn
        ? demoMode.processAccount(response.data[0])
        : response.data[0];
    }
    return null;
  }

  static async getAccountByAccountId(accountId: string) {
    const response = await axiosInstance.get<AccountData>(
      `/accounts/${accountId}`
    );

    return demoMode.isDemoModeOn
      ? demoMode.processAccount(response.data)
      : response.data;
  }

  static async getAccounts(params: any) {
    const response = await axiosInstance.get<AccountData[]>(`/accounts`, {
      params,
    });

    return demoMode.isDemoModeOn
      ? response.data.map(demoMode.processAccount)
      : response.data;
  }

  static async updateAccount(accountId: string, payload: any) {
    const response = await axiosInstance.patch<AccountData>(
      `/accounts/${accountId}`,
      payload
    );
    return demoMode.isDemoModeOn
      ? demoMode.processAccount(response.data)
      : response.data;
  }

  static async updateRefreshToken(code: string) {
    const response = await axiosInstance.post<AccountData>(
      `/accounts/updateRefreshToken`,
      { code }
    );
    return demoMode.isDemoModeOn
      ? demoMode.processAccount(response.data)
      : response.data;
  }

  static async deleteGoogleLink(email: string) {
    const response = await axiosInstance.delete<{ email: string }>(
      `/accounts/deleteGoogleLink`,
      { data: { email } }
    );
    return response.data;
  }

  static async registerAccount(payload: any) {
    const response = await axiosInstance.post<AccountData>(
      `/accounts/register`,
      payload
    );
    return demoMode.isDemoModeOn
      ? demoMode.processAccount(response.data)
      : response.data;
  }

  static async getCustomDashboards(params: any) {
    const response = await axiosInstanceNoLoading.get<CustomDashboardParams[]>(
      `/custom_dashboards/`,
      params
    );
    return response.data;
  }

  static async getCustomDashboard(dashboardId: any) {
    const response = await axiosInstance.get<CustomDashboardParams>(
      `/custom_dashboards/${dashboardId}`
    );
    return response.data;
  }

  static async createCustomDashboard(payload: any) {
    const response = await axiosInstance.post<CustomDashboardParams>(
      `/custom_dashboards/`,
      payload
    );
    return response.data;
  }

  static async updateCustomDashboard(dashboardId: number, payload: any) {
    const response = await axiosInstance.patch<CustomDashboardParams>(
      `/custom_dashboards/${dashboardId}`,
      payload
    );
    return response.data;
  }

  static async deleteCustomDashboard(dashboardId: number) {
    const response = await axiosInstance.delete(
      `/custom_dashboards/${dashboardId}`
    );
    return response.data;
  }

  static async getLocations(params: any) {
    const { headers, data } = await axiosInstance.get<LocationData[]>(
      `/locations`,
      {
        params,
      }
    );
    const locations = demoMode.isDemoModeOn
      ? data.map(demoMode.processLocation)
      : data;
    return {
      total: parseInt(headers["x-total-count"]),
      list: locations,
    };
  }

  static async getLabels(params: any, fetchWithoutBlock: boolean) {
    const axios = fetchWithoutBlock ? axiosInstanceNoLoading : axiosInstance;
    const response = await axios.get<LabelData[]>(`/labels`, {
      params,
    });
    return response.data;
  }

  static async deleteLocationLabel(locationId: number, labelId: number) {
    const response = await axiosInstance.delete(
      `/locations/${locationId}/labels/${labelId}`
    );
    return response.data;
  }

  static async addLocationLabel(locationId: number, labelId: number) {
    const response = await axiosInstance.put(
      `/locations/${locationId}/labels/${labelId}`
    );
    return response.data;
  }

  static async sendEmailToLocation(payload: any) {
    await axiosInstance.post(
      "/organizationsJobs/send_recommendation_mail",
      payload
    );
  }

  static async createLabel(label: any) {
    const response = await axiosInstance.post(`/labels`, label, {
      noHandleError: true,
    });
    return response.data;
  }

  static async getLocation(locationId: string) {
    const response = await axiosInstance.get<LocationData>(
      `/locations/${locationId}`
    );
    return demoMode.isDemoModeOn
      ? demoMode.processLocation(response.data)
      : response.data;
  }

  static async getRecommendations(params: any, noLoading: boolean) {
    const { data } = await (noLoading
      ? axiosInstanceNoLoading
      : axiosInstance
    ).get<(RawOrgRecommendationData | OrgRecommendationData)[]>(
      `/recommendations`,
      {
        params,
      }
    );

    let recommendations = data.map(sanitizeOrgRecommendationData);
    if (demoMode.isDemoModeOn) {
      recommendations = recommendations.map((rec) => ({
        ...rec,
        relevant_locations: rec.relevant_locations?.map(
          demoMode.processSummaryLocation
        ),
      }));
    }
    return recommendations;
  }

  static async getCustomOrgRecommendations(params: any, noLoading: boolean) {
    const { data } = await (noLoading
      ? axiosInstanceNoLoading
      : axiosInstance
    ).get<OrgRecommendationData[]>(`/recommendations/custom`, {
      params,
    });
    return demoMode.isDemoModeOn
      ? data.map((rec) => ({
          ...rec,
          relevant_locations: rec.relevant_locations?.map(
            demoMode.processSummaryLocation
          ),
        }))
      : data;
  }

  static async getStatsPrime(organizationId: string) {
    const response = await axiosInstanceNoLoading.get(`/stats/prime`, {
      params: { organization_id: organizationId },
    });
    return response.data;
  }

  static async getStatsCustomPrime(
    organizationId: string,
    customDashboardId: string
  ) {
    const response = await axiosInstanceNoLoading.get(`/stats/prime/custom`, {
      params: {
        organization_id: organizationId,
        custom_dashboard_id: customDashboardId,
      },
    });
    return response.data;
  }

  static async getStatsStatus(
    organizationId: string,
    customDashboardParams?: CustomDashboardParams
  ) {
    const response = await axiosInstanceNoLoading.get(`/stats/status`, {
      params: {
        organization_id: organizationId,
        ...(customDashboardParams
          ? { ...customDashboardParams.conditions }
          : {}),
      },
    });
    return response.data;
  }

  // start new

  static async getChartCollections(params: any) {
    const response = await axiosInstance.get("/chartCollections", { params });

    return response.data;
  }

  static async createCollection(payload: any) {
    const response = await axiosInstance.post(`/chartCollections`, payload);
    return response.data;
  }

  static async updateCollection(collectionId: string, payload: any) {
    const response = await axiosInstance.patch(
      `/chartCollections/${collectionId}`,
      payload
    );
    return response.data;
  }

  static async getChartCollectionsByCollectionId(collectionId: string) {
    const response = await axiosInstance.get<ChartData[]>(
      `/chartCollections/${collectionId}/charts`
    );

    return response.data;
  }

  static async getChartDataByTimeSeries(params: any) {
    // avoid sending unnecessary parameters.
    DataSvc.removePropertiesIfNoValue(params);
    const response = await axiosInstance.get(`/metrics/time_series`, {
      params,
    });

    return response.data;
  }

  static async getChartDataByGroupAggregations(params: any) {
    // avoid sending unnecessary parameters.
    DataSvc.removePropertiesIfNoValue(params);
    const response = await axiosInstance.get(`/metrics/group_aggregations`, {
      params,
    });

    return response.data;
  }

  static async createChartByTemplate(collectionId: string, params: any) {
    const response = await axiosInstance.post(
      `/chartCollections/${collectionId}/charts/createByTemplate`,
      {},
      {
        params,
      }
    );

    return response.data;
  }

  static async updateChart(
    collectionId: string,
    chartId: string,
    payload: any
  ) {
    // DataSvc.removePropertyIfNoValue(payload, 'municipality_filter');
    // DataSvc.removePropertyIfNoValue(payload, 'category_filter');
    // DataSvc.removePropertyIfNoValue(payload, 'label_filter');
    const response = await axiosInstance.patch(
      `/chartCollections/${collectionId}/charts/${chartId}`,
      payload
    );

    return response.data;
  }

  static async deleteChart(collectionId: string, chartId: string) {
    const response = await axiosInstance.delete(
      `/chartCollections/${collectionId}/charts/${chartId}`
    );
    return response.data;
  }

  // end new

  static async getMetrics(params: any) {
    const response =
      await axiosInstanceNoLoading.get<OrgDailyMetricsFromAPIData>(`/metrics`, {
        params,
      });
    return response.data;
  }

  static async getLocationEvents(params: any) {
    const response =
      await axiosInstanceNoLoading.get<OrgDailyMetricsFromAPIData>(
        `/metrics/location_events`,
        { params }
      );
    return response.data;
  }

  static async getCategories(fetchWithoutBlock: boolean) {
    const axios = fetchWithoutBlock ? axiosInstanceNoLoading : axiosInstance;
    const response = await axios.get<CategoryData[]>("/categories");
    return response.data;
  }

  static async getMunicipalities(organizationId: string) {
    const response = await axiosInstance.get<MunicipalityData[]>(
      "/municipalities",
      {
        params: {
          organization_id: organizationId,
          limit: 300,
        },
      }
    );
    return response.data;
  }

  static async getMunicipalitiesOfLocations(
    organizationId: string,
    fetchWithoutBlock: boolean
  ) {
    const axios = fetchWithoutBlock ? axiosInstanceNoLoading : axiosInstance;
    const response = await axios.get<MunicipalityData[]>(
      "/municipalities/locationMunis",
      {
        params: {
          organization_id: organizationId,
        },
      }
    );
    return response.data;
  }

  static async getMunicipalitiesOfUser(
    userId: number,
    fetchWithoutBlock: boolean
  ) {
    const axios = fetchWithoutBlock ? axiosInstanceNoLoading : axiosInstance;
    const response = await axios.get<MunicipalityData[]>(
      "/municipalities/locationMunisForUser",
      {
        params: {
          user_id: userId,
        },
      }
    );
    return response.data;
  }

  static async getMunicipalitiesParent() {
    const response = await axiosInstance.get(`/municipalities`, {
      params: {
        parent_only: true,
        limit: 100,
        sort: "external_id",
      },
    });
    return response.data;
  }

  static async getMunicipalitiesChild(external_id: string) {
    const response = await axiosInstance.get<MunicipalityData[]>(
      `/municipalities`,
      {
        params: {
          parent_id: external_id,
          limit: 300,
          sort: "external_id",
        },
      }
    );
    return response.data;
  }

  static async getOrganizations(params: any) {
    const response = await axiosInstance.get<OrganizationData[]>(
      `/organizations`,
      {
        params,
      }
    );
    return {
      total: parseInt(response.headers["x-total-count"]),
      list: demoMode.isDemoModeOn
        ? response.data.map(demoMode.processOrganization)
        : response.data,
    };
  }

  static async getOrganization(params: any) {
    const response = await axiosInstance.get<OrganizationData>(
      `/organizations/${params.organizationId}`
    );
    return demoMode.isDemoModeOn
      ? demoMode.processOrganization(response.data)
      : response.data;
  }

  static async getRanking(path: string, params: any) {
    const { data } = await axiosInstanceNoLoading.get(`/ranking/${path}`, {
      params,
    });
    if (path === "locations" && demoMode.isDemoModeOn) {
      return {
        ...data,
        ranking: data.ranking.map(demoMode.processLocation),
      };
    }
    return data;
  }

  static async getRecommendationsHistory(
    organization_id: string,
    size: number,
    noLoading: boolean
  ) {
    const response = await (noLoading
      ? axiosInstanceNoLoading
      : axiosInstance
    ).get(`/recommendations/history`, {
      params: {
        organization_id,
        size,
      },
    });

    return response.data;
  }

  static async getLocationRecommendationHistory(
    organization_id: string,
    location_id: string,
    size: number
  ) {
    const response = await axiosInstance.get(`/recommendations/history`, {
      params: {
        ...(organization_id && { organization_id }),
        location_id,
        size,
      },
    });

    return response.data;
  }

  static async getRecMessages(organization_id: string) {
    const { data } = await axiosInstance.get<RecMessageDataFromAPI[]>(
      `/org_recommendation_records`,
      {
        params: {
          organization_id,
        },
      }
    );

    const recData = demoMode.isDemoModeOn
      ? data.map((rec) => ({
          ...rec,
          relevant_locations: rec.relevant_locations.map(
            demoMode.processSummaryLocation
          ),
        }))
      : data;

    return recData;
  }

  static async getManagementLocations() {
    const { data } = await axiosInstance.post<LocationData[]>(
      "/management/locations/import"
    );
    return demoMode.isDemoModeOn ? data.map(demoMode.processLocation) : data;
  }

  static async registerLocation(locationId: number) {
    const response = await axiosInstance.post(
      `/management/locations/${locationId}/register`
    );

    return response.data;
  }

  static async deregisterLocation(locationId: number) {
    const response = await axiosInstance.post(
      `/management/locations/${locationId}/deregister`
    );

    return response.data;
  }

  static async deleteLocation(locationId: number) {
    const response = await axiosInstance.delete(`/locations/${locationId}`);
    return response.data;
  }

  static async deleteOrgUseLimits(organization_id: number) {
    const response = await axiosInstance.delete(
      `/featureUseLimit/org_use_limits/${organization_id}`
    );
    return response;
  }

  static async deleteUserUseLimits(user_id: number) {
    const response = await axiosInstance.delete(
      `/featureUseLimit/user_use_limits/${user_id}`
    );
    return response;
  }

  static async registerLocationWithOrg(locationId: number, orgId: number) {
    const response = await axiosInstance.put(
      `/locations/${locationId}/organizations/${orgId}`
    );
    return response.data;
  }

  static async deregisterLocationWithOrg(locationId: number, orgId: number) {
    const response = await axiosInstance.delete(
      `/locations/${locationId}/organizations/${orgId}`
    );
    return response.data;
  }

  static async createOrganization(payload: any) {
    const response = await axiosInstance.post("/organizations", payload);
    return response.data;
  }

  static async addMunicipalityToOrganization(
    organization_id: number,
    municipality_id: number
  ) {
    const response = await axiosInstance.put(
      `/organizations/${organization_id}/municipalities/${municipality_id}`
    );
    return response.data;
  }

  static async updateOrganization(organization_id: number, payload: any) {
    const response = await axiosInstance.patch(
      `/organizations/${organization_id}`,
      payload
    );
    return response.data;
  }

  static async updateLocations(location_id: number, payload: any) {
    const response = await axiosInstance.patch(
      `/locations/${location_id}`,
      payload
    );
    return response.data;
  }

  static async removeMunicipalityFromOrganization(
    organization_id: number,
    municipality_id: number
  ) {
    const response = await axiosInstance.delete(
      `/organizations/${organization_id}/municipalities/${municipality_id}`
    );
    return response.data;
  }

  static async removeOrganization(organization_id: string) {
    const response = await axiosInstance.delete(
      `/organizations/${organization_id}`
    );
    return response.data;
  }

  static async getOrgsLoginRate() {
    const response = await axiosInstance.get(`/organizations/orgs_login_rate`);
    return response.data;
  }

  static removePropertyIfNoValue(obj: any, propName: string) {
    if (typeof obj !== "object" || !propName) {
      return;
    }

    if (
      typeof obj[propName] === "undefined" ||
      obj[propName] === null ||
      obj[propName] === ""
    ) {
      delete obj[propName];
    }
    if (Array.isArray(obj[propName])) {
      const arr = obj[propName] as [];
      const concatArr = arr
        .map((it) => {
          return it ? it : "";
        })
        .join();
      if (concatArr.length === 0) {
        delete obj[propName];
      }
    }
  }

  static removePropertiesIfNoValue(obj: any) {
    if (typeof obj !== "object") {
      return;
    }
    for (const p in obj) {
      DataSvc.removePropertyIfNoValue(obj, p);
    }
  }

  static async getProfileCompletionLeaderboard() {
    const response = await axiosInstance.get("/ranking/org_prof_compl");

    return response.data;
  }

  // Internal tools

  static async getPlaceScores(formData: FormData) {
    const response = await axiosInstance.post(
      `/tools/get_review_scores`,
      formData
    );
    return response.data;
  }

  static async getFeatures(data: any) {
    const response = await axiosInstance.get(`/featureUseLimit/features`, {
      params: {
        ...data,
      },
    });
    return response.data;
  }

  static async getUseLimits(data: any) {
    const response = await axiosInstance.get(`/featureUseLimit/use_limits`, {
      params: {
        ...data,
      },
    });
    return response.data;
  }

  static async getUseLimitsForOrg(id: number | string) {
    const response = await axiosInstance.get(
      `/featureUseLimit/org_use_limits/${id}`
    );
    return response.data;
  }

  static async getUseLimitsForUser(id: number | string) {
    const response = await axiosInstance.get(
      `/featureUseLimit/user_use_limits/${id}`
    );
    return response.data;
  }

  static async updateOrgUseLimit(payload: any) {
    const response = await axiosInstance.patch(
      `/featureUseLimit/org_use_limit`,
      payload
    );
    return response.data;
  }

  static async updateUserUseLimit(payload: any) {
    const response = await axiosInstance.patch(
      `/featureUseLimit/user_use_limit`,
      payload
    );
    return response.data;
  }

  static async getReviews(data: any) {
    const response = await axiosInstance.get(`/tools/get_reviews`, {
      params: {
        ...data,
      },
    });
    return response.data;
  }

  static async getFacilityReviews(data: any) {
    const response = await axiosInstance.get("/reviews", {
      params: data,
    });
    return response.data;
  }

  static async postReplyReview(data: any) {
    const response = await axiosInstance.post("/reviews/reply", data);
    return response.data;
  }

  static async getReviewsForLocation(params: {
    locationId: number;
    skipNum?: number;
    nextSetToken?: string;
  }) {
    const response = await axiosInstance.get(
      `/tools/get_reviews_for_location`,
      {
        params,
      }
    );
    return response.data;
  }

  static async checkOwnerArea(data: any) {
    const response = await axiosInstance.post(`tools/check_owner_area`, data);

    return response.data;
  }

  static async checkOwnerList(formData: FormData, accessToken: string) {
    const response = await axiosInstance.post(
      `tools/check_owner_list?access_token=${accessToken}`,
      formData
    );

    return response.data;
  }

  static async checkOwner(data: any) {
    const response = await axiosInstance.get(`/tools/check_owner`, {
      params: {
        ...data,
      },
    });
    return response.data;
  }

  static async getToolsMetrics(data: {
    locationName: string;
    accountName: string;
    accessToken: string;
    dataStartDate: string;
    dataEndDate: string;
    useWeekly: boolean;
    comparisonStartDate: string;
    comparisonEndDate: string;
    comparisonTargetStartDate: string;
    comparisonTargetEndDate: string;
    skipNum: number | null;
  }): Promise<ToolsMetrics> {
    const response = await axiosInstance.get(`/tools/metrics`, {
      params: {
        ...data,
      },
    });
    return response.data;
  }

  static async getToolsMetricsForLocation(params: {
    locationId: number;
    dataStartDate: string;
    dataEndDate: string;
    useWeekly: boolean;
    comparisonStartDate: string;
    comparisonEndDate: string;
    comparisonTargetStartDate: string;
    comparisonTargetEndDate: string;
    skipNum: number | null;
  }): Promise<ToolsMetrics> {
    const response = await axiosInstance.get(`/tools/metrics_for_location`, {
      params,
    });
    return response.data;
  }

  static async getToolsAreaMetrics(data: any) {
    const response = await axiosInstance.get(`/tools/areaMetrics`, {
      params: {
        ...data,
      },
    });
    return response.data;
  }

  static async getToolsAreaMetricsForOrg(data: any) {
    const response = await axiosInstance.get(`/tools/areaMetrics_for_org`, {
      params: {
        ...data,
      },
    });
    return response.data;
  }

  static async sendMassEmail(payload: any) {
    await axiosInstance.post("/admin/mass_send_email", payload);
  }

  static async getMedia(params: any) {
    const response = await axiosInstance.get(`/media`, { params });
    return response.data;
  }

  static async createLocalPost(locationId: string, formData: FormData) {
    const response = await axiosInstance.post(
      `/locations/${locationId}/createLocalPost`,
      formData
    );
    return response;
  }

  static async generateLocalPostTextWithGemini(data: any) {
    const response = await axiosInstance.post(
      `/gemini/generate_loc_post_text/`,
      data
    );
    return response.data as string;
  }

  static async getLocalPosts(params: any) {
    const response = await axiosInstance.get(`/local_posts`, { params });
    return response.data;
  }

  static async getLocalPostIdeas(location_id: string) {
    const response = await axiosInstance.get(
      `/local_posts/ideas/${location_id}`
    );
    return response.data;
  }

  static async authorizeInstagram(payload: any) {
    const response = await axiosInstance.post(`/instagram/authorize`, payload);
    return response.data;
  }

  static async deleteInstagramIntegration(
    location_id: string,
    user_id: string
  ) {
    const response = await axiosInstance.delete(
      `instagram/remove_integration/location/${location_id}/user/${user_id}`
    );
    return response.data;
  }

  static async massPostLocalPosts(formData: FormData) {
    const response = await axiosInstance.post(
      `/local_posts/mass_post`,
      formData
    );
    return response.data;
  }
}
